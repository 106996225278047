<template>
  <div class="ph-inpage-widget ph-inpage-widget-bottom-right ph-inpage-widget--dynamic-twist">
            <div class="ph-inpage-widget__body">
                <div class="ph-inpage-widget__content">
                    <div class="ph-inpage-widget__now">Now</div>
                    <div class="ph-inpage-widget__txt">
                        <div class="ph-inpage-widget__title ph-inpage-widget__txt-overflow-1">{{adTitle}}</div>
                        <div class="ph-inpage-widget__desc ph-inpage-widget__txt-overflow-1">{{adText}}</div>
                    </div>
                    <div class="ph-inpage-widget__btns-row">
                        <div class="ph-inpage-widget__btns-row-item">
                            <a href="#" class="ph-inpage-widget__btn ph-inpage-widget__btn-cancel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M10.335 0.986495C10.8893 0.758356 11.4844 0.640625 12.0859 0.640625C12.6874 0.640625 13.2824 0.758356 13.8368 0.986495C14.391 1.21457 14.8931 1.54822 15.3149 1.96729C15.7369 2.38622 16.0705 2.88245 16.2977 3.42708C16.5249 3.97167 16.6416 4.55466 16.6416 5.14293C16.6416 5.7312 16.5249 6.31419 16.2977 6.85879C16.0705 7.40336 15.737 7.89955 15.315 8.31846V8.31846L9.16368 14.4278C9.02669 14.5638 8.83826 14.6406 8.64142 14.6406C8.44458 14.6406 8.25614 14.5638 8.11915 14.4278L1.96783 8.31846C1.11617 7.47262 0.641602 6.32996 0.641602 5.14293C0.641602 3.95591 1.11617 2.81325 1.96783 1.9674C2.82024 1.12082 3.98131 0.640995 5.19695 0.640995C6.4126 0.640995 7.57366 1.12082 8.42607 1.9674L8.64142 2.18128L8.85664 1.96752V1.96752C9.27848 1.5484 9.78068 1.21459 10.335 0.986495ZM14.2704 2.94159C13.9814 2.65447 13.6396 2.42792 13.265 2.27377C12.8905 2.11965 12.4899 2.04062 12.0859 2.04062C11.6819 2.04062 11.2813 2.11965 10.9068 2.27377C10.5322 2.42792 10.1904 2.65447 9.9014 2.94159L9.16368 3.67427C9.02669 3.81033 8.83826 3.88712 8.64142 3.88712C8.44458 3.88712 8.25614 3.81033 8.11915 3.67427L7.38155 2.94171C6.79815 2.36229 6.01185 2.04099 5.19695 2.04099C4.38206 2.04099 3.59575 2.36229 3.01235 2.94171C2.4282 3.52187 2.09615 4.31329 2.09615 5.14293C2.09615 5.97258 2.4282 6.76399 3.01235 7.34416L8.64142 12.9348L14.2705 7.34416C14.5596 7.05713 14.7903 6.71503 14.9479 6.3371C15.1056 5.95913 15.1871 5.55328 15.1871 5.14293C15.1871 4.73258 15.1056 4.32673 14.9479 3.94877C14.7903 3.57083 14.5595 3.22862 14.2704 2.94159Z" fill="#DB4444"></path>
                                    <path d="M14.2704 2.94159C13.9814 2.65447 13.6396 2.42792 13.265 2.27377C12.8905 2.11965 12.4899 2.04062 12.0859 2.04062C11.6819 2.04062 11.2813 2.11965 10.9068 2.27377C10.5322 2.42792 10.1904 2.65447 9.9014 2.94159L9.16368 3.67427C9.02669 3.81033 8.83826 3.88712 8.64142 3.88712C8.44458 3.88712 8.25614 3.81033 8.11915 3.67427L7.38155 2.94171C6.79815 2.36229 6.01185 2.04099 5.19695 2.04099C4.38206 2.04099 3.59575 2.36229 3.01235 2.94171C2.4282 3.52187 2.09615 4.31329 2.09615 5.14293C2.09615 5.97258 2.4282 6.76399 3.01235 7.34416L8.64142 12.9348L14.2705 7.34416C14.5596 7.05713 14.7903 6.71503 14.9479 6.3371C15.1056 5.95913 15.1871 5.55328 15.1871 5.14293C15.1871 4.73258 15.1056 4.32673 14.9479 3.94877C14.7903 3.57083 14.5595 3.22862 14.2704 2.94159Z" fill="#DB4444"></path>
                                </svg>
                                LIKE
                            </a>
                        </div>
                        <div class="ph-inpage-widget__btns-row-item">
                            <a href="#" class="ph-inpage-widget__btn ph-inpage-widget__btn-ok">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.641602 8.64063C0.641602 4.22235 4.22332 0.640625 8.6416 0.640625C13.0599 0.640625 16.6416 4.22235 16.6416 8.64063C16.6416 13.0589 13.0599 16.6406 8.6416 16.6406H1.36887C0.967213 16.6406 0.641602 16.315 0.641602 15.9134V8.64063ZM2.09615 8.64063C2.09615 5.02567 5.02665 2.09517 8.6416 2.09517C12.2566 2.09517 15.1871 5.02567 15.1871 8.64063C15.1871 12.2556 12.2566 15.1861 8.6416 15.1861H2.09615V8.64063Z" fill="#7C44DB"></path>
                                    <path d="M8.6416 2.09517C5.02665 2.09517 2.09615 5.02567 2.09615 8.64063V15.1861H8.6416C12.2566 15.1861 15.1871 12.2556 15.1871 8.64063C15.1871 5.02567 12.2566 2.09517 8.6416 2.09517Z" fill="#7C44DB"></path>
                                </svg>
                                MESSAGE
                            </a>
                        </div>
                    </div>
                    <div class="ph-inpage-widget__triangle2"></div>
                </div>
                <div class="ph-inpage-widget__circle ph-inpage-widget__open-content">
                    <div class="ph-inpage-widget__circle-thumb">
                     <img :src="require('@/assets/panel/images/inpage/empty.svg')"     v-show="!imgIcon" alt="" />
                    <img :src="imgIcon" v-show="imgIcon" />
                      </div>
                    <div class="ph-inpage-widget__circle-border"></div>
                    <div class="ph-inpage-widget__circle-border-2"></div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  props: ['adTitle','adText','imgIcon']
}
</script>